var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slip-all" },
    [
      !_vm.isShow
        ? _c(
            "div",
            { staticClass: "slip-all-content" },
            [
              _c(
                "a-button",
                {
                  attrs: { prefix: "piliangdaoru" },
                  on: { click: _vm.handleAdd },
                },
                [_c("span", [_vm._v("添加年终奖")])]
              ),
              _vm.isRequest && !_vm.dataList.length
                ? _c("CbNoData", { attrs: { width: "200" } })
                : _vm._l(_vm.dataList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.yearEndBounsId,
                        staticClass: "slip-all-item flex-align-center",
                      },
                      [
                        _c(
                          "CbStatistics",
                          {
                            staticClass: "p-statistics",
                            attrs: {
                              "data-list": item.list,
                              length: "3",
                              "is-title": true,
                              title: item.year + "年年终奖",
                              "title-width": 300,
                              "is-logo": true,
                              "is-function": true,
                            },
                          },
                          [
                            _c("template", { slot: "right" }, [
                              _c(
                                "div",
                                { staticClass: "handle-box" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { prefix: "chakan-chakanmingxi" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDetaile(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  !item.locked
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "edit",
                                          attrs: { prefix: "bianji" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEdit(item)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                  !item.locked
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "danger",
                                          attrs: { prefix: "shanchu" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(item)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _c("template", { slot: "logo" }, [
                              item.locked
                                ? _c("div", { staticClass: "logo" }, [
                                    _c(
                                      "svg",
                                      { staticClass: "iconpark-icon" },
                                      [
                                        _c("use", {
                                          attrs: { href: "#suoding" },
                                        }),
                                      ]
                                    ),
                                    _c("span", { staticClass: "locking" }, [
                                      _vm._v("已锁定"),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
            ],
            2
          )
        : _vm._e(),
      _vm.isShow
        ? _c("SlipTable", {
            attrs: { "yearend-bouns-id": _vm.yearendBounsId },
            on: { goBack: _vm.goBack },
          })
        : _vm._e(),
      _c("AnnualForm", { ref: "annualForm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }