/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
/**
 * @method: formatSeconds
 * @desc: 吧秒转换成时分秒返回
 * @param {boolean} time true 以：连接时分秒 false用汉字展示
 * @param {boolean} isFillZero 是否需要补0，不需要的话则展示1分20秒这样。
 * @return {string} xx小时xx分xx秒
 */
export function formatSeconds(value, time = false, isFillZero = true) {
  let result = parseInt(value)
  const h =
    Math.floor(result / 3600) < 10
      ? (!isFillZero ? '' : '0') + Math.floor(result / 3600)
      : Math.floor(result / 3600)
  const m =
    Math.floor((result / 60) % 60) < 10
      ? (!isFillZero ? '' : '0') + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60)
  const s =
    Math.floor(result % 60) < 10
      ? (!isFillZero ? '' : '0') + Math.floor(result % 60)
      : Math.floor(result % 60)
  // 不填充0的情况
  if (isFillZero) {
    result = time ? `${h}:${m}:${s}` : `${h}小时${m}分${s}秒`
  } else {
    result = ''
    if (h > 0) result += time ? `${h}:` : `${h}小时`
    if (m > 0) result += time ? `${m}:` : `${m}分`
    result += time ? `${s}` : `${s}秒`
  }
  return result
}

/**
 * @param date new Date()的对象
 * @param fmt 格式化的规则，例如yyyy/MM/dd  yyyy-MM-dd
 * @param yearNextNum 在这个时间后面几年的时间，例如传入的是2020-10-01 yearNextNum=1. 则返回2021-10-01
 * @returns 按照传入规则格式化之后的时间
 */
export function formatDate(date, fmt, yearNextNum = 0) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (Number(date.getFullYear()) + yearNextNum + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (var k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}
/* 秒转化为多少分多少秒 */
export function getTime(value) {
  if (value > 60 && value % 60 !== 0) {
    return parseInt(value / 60) + '分钟' + (value % 60) + '秒'
  } else if (value > 60 && value % 60 === 0) {
    return value / 60 + '分钟'
  } else {
    return value + '秒'
  }
}
/**
 * @method: debounce
 * @desc: 防抖函数
 * @param {function} func 需要执行的方法
 * @param {number} wait 间隔时间
 */
export function debounce(func, wait) {
  let timer
  return function() {
    const args = arguments // arguments中存着e
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string,string} path
 * @param 例如 {2020-10-11,2020-10-14}
 * @returns {Number}
 */
// 天数计算
// dateStart:开始日期   dateEnd:结束日期
export function dateMinus(dateStart, dateEnd) {
  const start = new Date(dateStart)
  const end = new Date(dateEnd)
  const days = end.getTime() - start.getTime()
  const day = parseInt(days / (1000 * 60 * 60 * 24))
  return day
}
